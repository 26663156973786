import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
Vue.use(Vant);
import { Buffer } from 'buffer';

window.Buffer = Buffer; // 将 Buffer 挂载到 window 对象，以便 TronWeb 可以使用它

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
