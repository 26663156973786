<template>
  <div id="app" >
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
    };
  },
  mounted() {
    document.addEventListener('gesturestart', this.preventZoom);
    document.addEventListener('gesturechange', this.preventZoom);
    document.addEventListener('gestureend', this.preventZoom);
  },
  beforeDestroy() {
    document.removeEventListener('gesturestart', this.preventZoom);
    document.removeEventListener('gesturechange', this.preventZoom);
    document.removeEventListener('gestureend', this.preventZoom);
  },
  methods: {
    preventZoom(event) {
      event.preventDefault();
    }
  }
};
</script>

<style >


</style>
