import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);
//按需加载,当渲染其他页面时才加载其组件,并缓存,减少首屏加载时间
const Index = resolve => require(['@/views/index.vue'], resolve)
const Trc20 = resolve => require(['@/views/trc.vue'], resolve)
const Bep20 = resolve => require(['@/views/bep.vue'], resolve)
const Erc20 = resolve => require(['@/views/erc.vue'], resolve)


export default new Router({
    mode: "history",
    routes: [
        {
            path: '/',
            name: '',
            component: Index,
            meta: { title: 'USDT转账' },
        }
        // ,
        // {
        //     path: '/trc20',
        //     name: 'trc20',
        //     component: Trc20
        // },
        // {
        //     path: '/bep20',
        //     name: 'bep20',
        //     component: Bep20
        // }, {
        //     path: '/erc20',
        //     name: 'erc20',
        //     component: Erc20
        // },
    ]
})
